import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/SEO/seo"
import Layout from "../components/layout"
import Article from "../components/article"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {blogPostContainer, blogTitle, blogPost, blogPostContent, blogPublishedDate} from "./blog-post.module.css"

export default function Template({
  data, pageContext
}) {
  const post = data.markdownRemark
  const { previous, next } = pageContext
  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Article>
        <Row>
          <Col md={12} sm={12}>
            <p className={blogPublishedDate}>{post.frontmatter.date}</p>
            <h1 className={blogTitle}>{post.frontmatter.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col md={12} sm={12}>
            <div className={blogPostContainer}>
              <div className={blogPost}>
                <div
                  className={blogPostContent}
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Article>
      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY")
        description
      }
    }
  }
`